import React from 'react';
import './NavMob.css';
import { NavLink } from 'react-router-dom';


class Nav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ikon: 'nav_____mobile____box',
      swoubg: 'bg_____mobile',
      swoulink: 'link_____mobile',
    }
    this.showMobNav = this.showMobNav.bind(this);
  }
  showMobNav() {

    if (this.state.ikon === 'nav_____mobile____box') {
      this.setState({ ikon: 'nav_____mobile____box open_____nav_____mobile____box' });
      this.setState({ swoubg: 'bg_____mobile  open____bg_____mobile' });
      setTimeout(() => {
        this.setState({ swoulink: 'link_____mobile  open_____link_____mobile' });
      }, 600);
    } else {
      this.setState({ ikon: 'nav_____mobile____box' });
      this.setState({ swoubg: 'bg_____mobile' });
      this.setState({ swoulink: 'link_____mobile' });
    }
  }
  render() {
    return (
      <nav>
        <div onClick={this.showMobNav} className="nav_____mobile">
          <div className="title_____mobile">меню</div>
          <div className={this.state.ikon}  >
            <div className="cl____box"></div>
            <div className="top____box">
              <span></span>
            </div>
            <div className="center____box">
              <span className="left"></span>
              <span className="right"></span>
            </div>
            <div className="bottom____box">
              <span></span>
            </div>
          </div>
          <div className={this.state.swoubg}></div>
        </div>
        <div className={this.state.swoulink}>
          {this.props.routes.map((route) => (
            <NavLink

              onClick={this.showMobNav}

              key={route.path}
              to={route.path}
              activeClassName="active"
              exact
            >
              {route.name}
            </NavLink>
          ))}
          <a href="https://agro-foto.ru/">слайдотека агро-фото</a>
          <a href="http://gallery.tekpolska.pl/">слайдотека ТЕК</a>
        </div>
      </nav>
    )
  }
}

export default Nav;
