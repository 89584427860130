import React from 'react';
import { YMaps, Map, Placemark } from "react-yandex-maps";

const mapState = {
  center: [56.115022, 37.802598],
  zoom: 12
}
class YandexMaps extends React.Component {

  state = {
    mapState: true,
    placemarks: [],
  };
  toggleMap() {
    const { mapState } = this.state;
    this.setState({ mapState: !mapState });
  }
  render() {
    return (
      <div className="YandexMaps" >
        <YMaps>
          <div id="map-basics">
            <Map state={mapState} width="100%" height="100vh" >
              <Placemark
                modules={['geoObject.addon.balloon']}
                defaultGeometry={
                  [56.115022, 37.802598]
                }
                properties={{
                  iconImageSize: [42, 42],
                  balloonContentHeader: 'компания ООО «УПАКОВКА ДЛЯ СЕМЯН»',
                  balloonContentBody:
                    '  <a href="tel:84955240609">+7 (495) 524-06-09</a><br> <a href="tel:89165153512">+7 (916) 515-35-12</a> <br> <a href="mailto:press@agro-pak.ru">press@agro-pak.ru</a>',
                }}
              />
            </Map>
          </div>
        </YMaps>
      </div>
    );
  }
}

export default YandexMaps;
