import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Index from './index/Index';
import Prodacts from './prodacts/Prodacts';
import Copm from './copm/Copm';
import Faq from './faq/Faq';
import Contacts from './contacts/Contacts';

const routes = [
    { path: '/', name: 'главная', Component: Index },
    { path: '/prodacts', name: 'продукция', Component: Prodacts },
    { path: '/compani', name: 'о компании', Component: Copm },
    { path: '/faq', name: 'вопрос ответ', Component: Faq },
    { path: '/contact', name: 'контакты', Component: Contacts },

]

ReactDOM.render(
    <App routes={routes} />,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
