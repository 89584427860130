import React from 'react';
import './Faq.css';




class Faq extends React.Component {
  constructor(props) {
    super();
    this.state = {
      h1: 'вопрос ответ',
    }
    this.question = React.createRef();
    this.questions = React.createRef();
    this.answer = React.createRef();
  }
  componentDidMount() {
    let answers = document.querySelectorAll('.answer');
    this.props.fag.forEach((element, index) => {
      answers[index].innerHTML = this.props.fag[index].answer;
    });
  }
  switchquestion(e) {
    e.currentTarget.classList.toggle("active");
    e.currentTarget.nextSibling.classList.toggle("answer-active");
  }
  render() {
    return (
      <>
        <div className='Faq'>
          <div className='head'>
            <h1>{this.state.h1}</h1>
          </div>
          <div className='faq-box'>
            <div ref={this.questions} className='faq-box-question'>
              <br />   <br />
              <h2>ответы на частые вопросы</h2>
              {Object.keys(this.props.fag).map((keyname, i) =>
              (
                <div key={keyname} className='question'>
                  <button ref={this.question} onClick={this.switchquestion.bind(this)} >{this.props.fag[keyname].question}</button>
                  <div ref={this.answer} className='answer'></div>
                </div>
              )
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Faq;
