import React from 'react';
import './contacts.css';
import Title from '../Title/Title';
import YandexMaps from '../YandexMaps/YandexMaps';

class Сontacts extends React.Component {
  constructor() {
    super();
    this.state = {
      h1: 'контакты',
      title: 'контакты',
      map: null,
    }
    this.cloze = React.createRef();
  }
  componentDidMount() {
    this.setState({ map: <YandexMaps /> });
  }
  switchquestion(e) {
    e.currentTarget.classList.toggle("active");

    e.currentTarget.offsetParent.classList.toggle("cont-active");
  }
  render() {
    return (
      <>
        <Title pageTitle={this.state.title} />
        <div className='contacts'>
          {this.state.map}
          <div className='head'>
            <h1>{this.state.h1}</h1>
          </div>
          <div className='contacts-box'>
            <div className='contacts-descr'>
              <button className='active' onClick={this.switchquestion.bind(this)} > </button>
              <div className='contatc'>
                <b>Телефоны :</b>
                <a href="tel:84955240609">+7 (495) 524-06-09</a>
                <a href="tel:89165153512">+7 (916) 515-35-12</a>
                <b>Email :</b>
                <a href="mailto:press@agro-pak.ru">press@agro-pak.ru</a>
                <div className='for-mob'>
                  <b>Маршрут :</b>
                  <p>
                    <a href="yandexnavi://build_route_on_map?lat_to=56.115081&amp;lon_to=37.802883" alt="яндекс навигатор" rel="noopener noreferrer" target='_blank'>яндекс навигатор</a>
                  </p>
                </div>
              </div>
              <div className='adres'>
                <p><b>Фактический адрес: </b><br />
                  Россия, Московская область,
                  Пушкинский район, село Ельдигино,
                  территория Совхозная 1</p>

              </div>


            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Сontacts;
