import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group';
import Nav from './header/nav/Nav';
import NavMob from './header/navMob/NavMob';
import Header from './header/Header';
import { useState } from 'react';

let title = 'Семена в профессиональной упаковке, производство упаковки для цветов и саженцев от компании -  Упаковка для семян';
let linksite = '/';
let namesite = "компания ООО «УПАКОВКА ДЛЯ СЕМЯН»";
const data = require('./data/data.json');
const question = require('./data/question.json');

export default function App(props) {
  const [cl, setCl] = useState("up");

  return (
    <Router>
      <>
        <Header title={title} linksite={linksite} namesite={namesite} position={'header-center'} />
        <Nav routes={props.routes} />
        <NavMob routes={props.routes} />
        {props.routes.map(({ path, Component }) => (
          <Route key={path} exact path={path}>
            {({ match }) => (
              <CSSTransition
                in={match != null}
                timeout={2000}
                classNames={cl}
                unmountOnExit
              >
                <div className={cl}>
                  <Component servis={data} fag={question} />
                </div>
              </CSSTransition>
            )}
          </Route>
        ))}
      </>
    </Router>
  )
};