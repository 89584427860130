import React from 'react';
import './Nav.css';
import { NavLink } from 'react-router-dom';


class Nav extends React.Component {
  render() {
    return (
      <div className='Nav'>
        {this.props.routes.map((route) => (
          <NavLink
            key={route.path}
            to={route.path}
            activeClassName="active"
            exact
          >
            {route.name}
          </NavLink>
        ))}
        <a href="https://agro-foto.ru/">слайдотека агро-фото</a>
        <a href="http://gallery.tekpolska.pl/">слайдотека ТЕК</a>
      </div>
    )
  }
}

export default Nav;
