import React from 'react';
import './Index.css';
import Title from '../Title/Title';
import Video from '../Video/Video';
import bg from '../img/index-bg0.webp';
import { NavLink } from 'react-router-dom';

class Index extends React.Component {
  constructor() {
    super();
    this.state = {
      w: window.innerWidth,
      h: window.innerHeight,
      title: 'Производство упаковки для цветов и саженцев от компании',
      x: 50,
      y: 50,
    };
    this.rect = React.createRef();
    this.rectbox = React.createRef();
    this.corobki = React.createRef();
    this.poketi = React.createRef();
    this.reklama = React.createRef();
    this.galery = React.createRef();
    this.resizeRect = this.resizeRect.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.resizeRect);
    this.rectbox.current.style.background = 'url( ' + { bg } + ' ) center center / cover';
    this.corobki.current.classList.add('corobki');
    this.poketi.current.classList.add('poketi');
    this.reklama.current.classList.add('reklama');
    this.galery.current.classList.add('galery');

    if (window.innerHeight <= 520) {
      if (window.innerWidth >= (window.innerHeight + (window.innerHeight * .41))) {
        this.setState({ w: window.innerWidth + 70 });
      } else {
        this.setState({ w: (window.innerHeight + (window.innerHeight * .41) + 160) });
      }
    } else {
      if (window.innerWidth >= (window.innerHeight + (window.innerHeight * .41))) {
        this.setState({ w: window.innerWidth });
      } else {
        this.setState({ w: (window.innerHeight + (window.innerHeight * .41)) });
      }
    }
  }
  componentWillUnmount() {
    window.addEventListener("resize", null);
    let bg = document.querySelector('.rect-box');
    bg.style.opacity = 0;
  }
  resizeRect(w, h, event) {
    if (window.innerHeight <= 520) {
      if (window.innerWidth >= (window.innerHeight + (window.innerHeight * .41))) {
        this.setState({ w: window.innerWidth + 70 });
      } else {
        this.setState({ w: (window.innerHeight + (window.innerHeight * .41) + 160) });
      }
    } else {
      if (window.innerWidth >= (window.innerHeight + (window.innerHeight * .41))) {
        this.setState({ w: window.innerWidth });
      } else {
        this.setState({ w: (window.innerHeight + (window.innerHeight * .41)) });
      }
    }

  }


  render() {
    return (
      <>
        <Title pageTitle={this.state.title} />
        <div className='enter'>
          <div className='rect-box' ref={this.rectbox} >
            <div ref={this.rect} style={{ width: ((this.state.w / 5) - (this.state.w * .040)), height: ((this.state.w / 5) - (this.state.w * .040)) }} className='rect'>
              <div className='intro-box'>
                <div className='intro' style={{ width: (this.state.w / 3.5), height: (this.state.w / 3.5) }} >
                </div>
              </div>
              <div ref={this.reklama} className='seed reklama ' style={{ left: (this.state.w / 5) - (this.state.w * .034) }}>
                <span className='pacet-box styleimport'>
                  <NavLink
                    to={"/prodacts"}
                    activeClassName="active"
                    className='link-prod '
                    exact
                  >
                    рекламная продукция
                  </NavLink>
                </span>
                <div className='seed slaid2' style={{ top: -(this.state.w / 5) + (this.state.w * .034) }}>

                </div>
                <div className='seed corobki2' ref={this.corobki}>
                  <a className='link-prod link-prod-right' href="/prodacts" >упаковка из картона</a>
                </div>
              </div>
              <div className='seed ' ref={this.poketi} style={{ left: -(this.state.w / 5) + (this.state.w * .034) }}>
                <span className='pacet-box styleimport'>
                  <NavLink
                    to={"/prodacts"}
                    activeClassName="active"
                    className='link-prod'
                    exact
                  >
                    пакеты для семян
                  </NavLink>
                </span>
                <div className='seed slaid1' ref={this.corobki} style={{ bottom: -(this.state.w / 5) + (this.state.w * .034) }}></div>
              </div>
              <div className='seed' ref={this.corobki} style={{ top: (this.state.w / 5) - (this.state.w * .034) }}>
                <NavLink
                  to={"/prodacts"}
                  activeClassName="active"
                  className='link-prod'
                  exact
                >
                  упаковка из картона
                </NavLink>
              </div>
              <div className='seed slaid1' style={{ bottom: (this.state.w / 5) - (this.state.w * .034) }}>
                <div className='seed ' ref={this.galery}>
                  <a className='link-prod link-prod-right' target='_blank' rel="noopener noreferrer" href="https://agro-foto.ru/">слайдотека</a>
                </div>
              </div>
            </div>
          </div>
        </div >
        <Video />
      </>
    );
  }
}

export default Index;
