import React from 'react';
import './Header.css';



class Header extends React.Component {

  render() {
    return (
      <>
        <div className={this.props.position}>
          <div className='header-box'>
            <div className="logo_____settings">
              <a className='logolink' href="/">
                <img src="/logo.svg" alt="логотип УДС" />
              </a>
            </div>
            <div className='info'>
              <a href='tel:+74965000381'>+7 (496) 500 03 81</a>
              <a href='tel:+79165153512'>+7 (916) 515 35 12</a>
              <a className='mail' href='mailto:'>press@agro-pak.ru</a>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Header;
