import React from 'react';
import './copm.css';
import Title from '../Title/Title';



class Copm extends React.Component {
  constructor(props) {
    super();
    this.state = {
      h1: 'о компании',
      title: 'О компании ',
    }
  }
  render() {
    return (
      <>
        <Title pageTitle={this.state.title} />
        <div className='copm'>
          <div className='head'>
            <h1>{this.state.h1}</h1>
          </div>
          <div className='comp-box'>
            <div className='comp-descr'>
              <span>
                <h2> компания ООО «УПАКОВКА ДЛЯ СЕМЯН»</h2>
              </span>
              <p>
                <b>ООО "УПАКОВКА ДЛЯ СЕМЯН"</b> - производственная компания, имеющая собственную полиграфию полного цикла, поэтому вы можете в кратчайшие сроки получить качественную упаковку с неповторимым дизайном. В нашей команде трудятся креативные люди, профессионалы своего дела. Они помогут разработать уникальный работающий продукт для каждого клиента, ведь упаковка - это не просто средство хранения семян, но и реклама, визуальный имидж компании.
                Упаковка разрабатывается совсем не просто. Процесс начинается с первоначального эскиза, составленного с учетом пожеланий клиента, уточняются размеры и информационное наполнение. В этот момент происходит активная работа с заказчиком. Выявляются его потребности и конкретное направление деятельности, вкусы и личное видение готовой продукции.<br /> Таким образом, каждое производство упаковки из картона имеет свой индивидуальный оттенок.
              </p>
              <p>Мы работаем на территории: России, Киргизии, Узбекистана, Казахстана и Армении.</p>

            </div>
            <div className='clienti'>
              <span>
                <h3>Hаши клиенты:</h3>
              </span>

              <div>
                <img src={require("../img/clienti/logo1.png")} alt="" />
                <img src={require("../img/clienti/logo2.png")} alt="" />
                <img src={require("../img/clienti/logo3.png")} alt="" />
                <img src={require("../img/clienti/logo4.png")} alt="" />
                <img src={require("../img/clienti/logo5.png")} alt="" />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Copm;
