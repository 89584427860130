import React from 'react';
import './video.css';
import intro from '../img/intro.mp4';
import poster from '../img/poster.svg'


class Video extends React.Component {
  constructor(props) {
    super(props);

    this.indexbgvideo = React.createRef();
    this.poster = React.createRef();
    this.vid = React.createRef();
    this.close = React.createRef();
    this.buttonIntro = React.createRef();
    this.VideoIndex = this.VideoIndex.bind(this);
    this.VideoPlay = this.VideoPlay.bind(this);
    this.videoClose = this.videoClose.bind(this);

  }
  componentDidMount() {
  }
  componentWillUnmount() {
    window.addEventListener("resize", null);
  }
  VideoIndex() {

    this.indexbgvideo.current.classList.add('bgvideoopen');
    this.poster.current.style.opacity = 1;
    if (window.innerWidth > window.innerHeight) {
      this.vid.current.style.width = (window.innerWidth + (window.innerWidth - window.innerHeight)) + 'px';
    }
    else if (window.innerWidth <= window.innerHeight) {
      this.vid.current.style.width = (window.innerWidth + (window.innerHeight * 1.5)) + 'px';
    }
    setTimeout(() => {
      this.indexbgvideo.current.classList.add('bordradius');
      this.poster.current.style.opacity = 1;
      this.buttonIntro.current.style.width = '62px';
      this.buttonIntro.current.style.height = '62px';
      this.close.current.classList.add('button-close-active');
      this.buttonIntro.current.style.display = 'block';
      this.buttonIntro.current.classList.add('buttonIntro-play');
      this.buttonIntro.current.classList.remove('buttonIntro-pause');
      let video = document.querySelector('.video');
      video.play();
    }, 600);
  }

  VideoPlay() {
    let video = document.querySelector('.video');
    if (video.paused === true) {
      video.play();
      this.buttonIntro.current.classList.remove('buttonIntro-pause');
      this.buttonIntro.current.classList.add('buttonIntro-play');
    } else {
      video.pause();
      this.buttonIntro.current.classList.remove('buttonIntro-play');
      this.buttonIntro.current.classList.add('buttonIntro-pause');
    }
  }
  videoClose() {
    let video = document.querySelector('.video');
    this.close.current.classList.remove('button-close-active');
    this.buttonIntro.current.style.display = 'none';
    video.pause();
    this.indexbgvideo.current.classList.remove('bordradius');
    setTimeout(() => {
      this.poster.current.style.opacity = 0;
      this.indexbgvideo.current.classList.remove('bgvideoopen');
      this.buttonIntro.current.style.width = '0';
      this.buttonIntro.current.style.height = '0';
    }, 600);
  }


  render() {
    return (
      <>
        <div className='bgvideoout' ref={this.indexbgvideo} onClick={this.VideoIndex}>
          <div className='poster-video' ref={this.poster}>
            <video className="video" ref={this.vid} preload="metadata" poster={poster}>
              <source src={intro} type="video/mp4" />
            </video>

          </div>
        </div>
        <div className='button-intro' ref={this.buttonIntro} onClick={this.VideoPlay} ></div>
        <div className='button-close' ref={this.close} onClick={this.videoClose}></div>
      </>
    );
  }
}
export default Video;
