import React from 'react';
import './Prodacts.css';
import Title from '../Title/Title';



class Prodacts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      h1: 'продукция',
      title: 'Продукция',
      activClass: 0,
      w: window.innerWidth,
      h: window.innerHeight,
      classSlide: ['left', 'center', 'right', 'right-no-show']
    }
    this.img = React.createRef();
    this.names = React.createRef();
    this.desctext = React.createRef();
    this.listProd = React.createRef();
    this.sliders = React.createRef();
    this.boxinfo = React.createRef();
    this.buttoninfo = React.createRef();
    this.openInfo = this.openInfo.bind(this);
    this.switchProdact = this.switchProdact.bind(this);
    this.switchSilde = this.switchSilde.bind(this);
    this.ProdactClass = this.ProdactClass.bind(this);
    this.resizeslide = this.resizeslide.bind(this);
  }
  componentDidMount() {
    let slides = this.sliders.current.children;
    for (let i = 0; i < slides.length; i++) {
      slides[i].classList.add('right-no-show');
      slides[i].style.height = (this.state.h - 100) + 'px ';
      slides[i].style.width = (this.state.w / 3) + 'px ';
    }
    for (let i = 0; i < this.props.servis.length; i++) {
      slides[i].classList.remove('right-no-show');
      slides[i].classList.add(this.state.classSlide[i]);

    }
    this.ProdactClass();
    let classSlide = ['left', 'center', 'right', 'right-no-show'];
    this.timeoutSlideMarket = setInterval(() => {
      this.startSliderMarket(classSlide);
    }, 3000);
    window.addEventListener("resize", this.resizeslide(slides));



  }


  startSliderMarket(classSlide) {
    let slides = this.sliders.current.children;
    classSlide.push(classSlide.shift());
    for (let i = 0; i < classSlide.length; i++) {
      slides[i].classList.value = classSlide[i];
    }
  }
  openInfo() {
    console.log('dfsdf');
    this.boxinfo.current.classList.toggle('text-box-active');
    this.buttoninfo.current.classList.toggle('button-help-active');
    if (this.buttoninfo.current.innerHTML === "подробности") {
      this.buttoninfo.current.innerHTML = "закрыть";
    } else {
      this.buttoninfo.current.innerHTML = "подробности";
    }
  }
  ProdactClass() {
    this.names.current.childNodes.forEach(element => {
      element.classList.remove('active');
    });

    this.desctext.current.innerHTML = this.props.servis[this.state.activClass].desc;
    this.names.current.children[this.state.activClass].classList.add('active');
  }
  switchProdact(i) {
    this.setState({ activClass: i });
    setTimeout(() => {
      this.ProdactClass();
    }, 100);

  }
  switchSilde(e) {
    // console.log(e.currentTarget);
  }
  componentWillUnmount() {
    window.addEventListener("resize", null);
    clearTimeout(this.timeoutSlideMarket);
  }

  resizeslide(slides) {
    if (window.innerWidth <= 900) {
      for (let i = 0; i < slides.length; i++) {
        slides[i].style.height = (this.state.h - 100) + 'px ';
        slides[i].style.width = (this.state.w / 1.5) + 'px ';
      }
    } else {
      for (let i = 0; i < slides.length; i++) {
        slides[i].style.height = (this.state.h - 100) + 'px ';
        slides[i].style.width = (this.state.w / 3) + 'px ';
      }
    }


  }


  render() {
    return (
      <>
        <Title pageTitle={this.state.title} />

        <div className='prodact' onScroll={this.openInfo}>
          <div className='head'>
            <h1>{this.state.h1}</h1>
          </div>
          <div className='prodact-list'>
            <ul ref={this.names}>
              {Object.keys(this.props.servis).map((keyname, i) =>
                (<li key={keyname} onClick={() => this.switchProdact(i)} data={i}>{this.props.servis[keyname].name}</li>)
              )}
            </ul>
          </div>
          <div className='slider-box'>
            <div className='sliders' ref={this.sliders}>
              {Object.keys(this.props.servis[this.state.activClass].img).map((keyname, i) =>
                (<div key={keyname} ref={this.img} onClick={this.switchSilde.bind(this)} style={{ backgroundImage: `url( ${this.props.servis[this.state.activClass].img[i]} ` }}></div>)
              )}
            </div>
          </div>
          <div className='text-box' ref={this.boxinfo}>
            <div className='text-for-prodact'>
              <div className='text'>
                <span>
                  <button className='button-help' onClick={this.openInfo} ref={this.buttoninfo}>подробности</button>
                  <span ref={this.desctext}></span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Prodacts;
